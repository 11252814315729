.createSchedule {
  button,
  [type='button'],
  [type='reset'],
  [type='submit'] {
    -webkit-appearance: button;
    background-color: #7c0000 !important;
    background-image: none;
  }
}
