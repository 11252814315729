@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;500;600;700&display=swap");

* {
  scroll-behavior: smooth;
  font-family: "IBM Plex Mono", monospace;
}

a {
  text-decoration: none !important;
  color: #000 !important;
}

/* prosidebar active style start */
.active {
  width: 100% !important;
  background-color: #f2f2fe;
  color: #000 !important;
}

.active p {
  color: #000;
}

.dashIconActive {
  color: #7c0000;
}

/* prosidebar active style end */

::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #65656566;
  border-radius: 10px;
}

.phone > input {
  outline: none;
}

/* button,
[type='button'],
[type='reset'],
[type='submit'] {
  background-color: #7c0000 !important;
} */

.ant-picker-ok {
  button[disabled] {
    background-color: #f5f5f5 !important;
  }

  button:not([disabled]) {
    background-color: #7c0000;
  }
}

// date picker
.rs-picker-daterange-menu {
  padding-right: 3rem !important;
}
